<template>
    <div>
        <el-dialog :visible.sync="show_dialog" :append-to-body="mobile_mode" @close="closeDialog" v-mobile-dialog v-if="mobile_mode"
                   :close-on-click-modal="false" >
            <div slot="title" class="text-primary">{{single.course_number}} | {{single.course_name}}</div>
            <div>
                <meeting-info :single="single" :mobile_mode="mobile_mode"></meeting-info>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button v-if="user.role_level > 2" class="pull-left" size="small" slot="footer" type="danger" plain @click="lockMeeting">锁定会议</el-button>
                <el-button v-if="user.role_level > 2" class="pull-left" size="small" type="success" plain @click="openMeeting">开启会议</el-button>

                <el-button type="" @click="closeDialog()">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="show_dialog" :append-to-body="mobile_mode" @close="closeDialog"  v-else width="auto" style="min-width:400px;max-width:700px;margin:auto;"
                   :close-on-click-modal="false" >
            <div slot="title" class="text-primary">{{single.course_number}} | {{single.course_name}} <span  v-if="single.is_closed == 1" class="ml-3 text-danger">| 课程已结束</span></div>
            <div>
                <meeting-info :single="single" :mobile_mode="mobile_mode"></meeting-info>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button v-if="user.role_level > 2" class="pull-left" size="small" slot="footer" type="danger" plain @click="lockMeeting">锁定会议</el-button>
                <el-button v-if="user.role_level > 2" class="pull-left" size="small" type="success" plain @click="openMeeting">开启会议</el-button>

                <el-button type="" @click="closeDialog()">确 定</el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script>
import {mapState} from "vuex";
import MeetingInfo from "./meeting-info";
export default {
    name: "lesson-view",
    data() {
        return {
            show_dialog:false,
        }
    },
    components: {MeetingInfo},
    computed:{
        ...mapState(['user'])
    },
    props: {
        view_mode: {
            type: Boolean,
            default() {
                return false;
            }
        },
        single: {
            type: Object,
            default() {
                return {};
            }
        },
        mobile_mode:{
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    watch: {
        view_mode: {
            handler(newVal) {
                this.show_dialog = newVal;
            }
        },
    },
    methods: {
        closeDialog(){
            this.$emit("update:view_mode",false);
        },
        lockMeeting(){
            this.$api('Admin.Course.Lock',{id:this.single.id},1).then(()=>{
                this.$notify.success('会议被强制结束和锁定!');
            });
        },
        openMeeting(){
            this.$api('Admin.Course.Unlock',{id:this.single.id},1).then(()=>{
                this.$notify.success('会议被开启!');
            });
        },
    }
}
</script>

<style scoped>

</style>
