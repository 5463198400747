<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     btn_review
                     @review="handleReview"
                     @reload="handleReload"
                     @edit="handleEdit"
                     btn_download
                     @download="handleDownload"
        >
            <div class="pull-left pr-2" style="padding-top: 2px;">
                <el-radio-group v-model="search.type" size="medium" fill="#607D8B" @change="reload">
                    <el-radio-button :label="0">当前</el-radio-button>
                    <el-radio-button :label="1">结束</el-radio-button>
                </el-radio-group>
            </div>
        </front-table>
        <edit-dialog :single="single" :edit_mode="edit_mode" dual :fields="fields" :model="model"
                     @success="handleSuccess" @cancel="handleCancel"></edit-dialog>
        <lesson-view :single="single" :view_mode.sync="view_mode"></lesson-view>
        <div class="clearfix"></div>
        <div style="height: 1px;overflow: hidden" ref="excel_table">
            <table >
                <tbody>
                <tr v-for="(x,i) in table" :key="i">
                    <td v-for="(y,j) in x" :key="j" v-html="y"></td>
                </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>

<script>
    import FrontTable from "../components/front-table";
    import FrontMixins from "../common/mixins/front-mixins";
    import LessonView from "@/components/lesson-view";
    import EditDialog from "@/components/edit-dialog";
    import XLSX from "xlsx";
    import FileSaver from "file-saver"

    export default {
        mixins: [FrontMixins],
        name: 'admin-course',
        components: {EditDialog, FrontTable,LessonView},
        data(){
            return {
            virtual_rooms:{},
                view_mode:false,
                use_cache:false,
                table:[],
        }},
        created() {
            this.model =  'Admin.' + this.$route.meta.model;
        },
        methods:{
            jumpUpload(){
                this.$router.push('/admin/import-course');
            },
            lockMeeting(){
                this.$api(this.model+'.Lock',this.single,1).then(()=>{
                    this.$notify.success('会议已经结束，并锁定!');
                })
            },
            unlockMeeting(){
                this.$api(this.model+'.Unlock',this.single,1).then(()=>{
                    this.$notify.success('会议已经开放!');
                })
            },
            handleDownload(id){
                this.$api(this.model+'.Participants',{id:id},1).then((res)=>{
                    this.table = res.data.data;
                    this.$nextTick(function () {
                        this.exportExcel();
                    })
                })
            },
            // 导出表格
            exportExcel() {
                /* generate workbook object from table */
                let wb = XLSX.utils.table_to_book(this.$refs['excel_table'],{raw:true});
                /* get binary string as output */
                let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
                let now = new Date().Format('yyyy-MM-dd');
                try {
                    FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), `与会人清单-${now}.xlsx`)
                } catch (e) {
                    console.log(e, wbout)
                }
                return wbout
            },
        }
    }

</script>

<style lang="scss">

</style>
